import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { createBrowserHistory } from 'history';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faHome,
  faStar,
  faStarHalfAlt,
  faCheck,
  faPhoneVolume,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebookSquare, faYoutube } from '@fortawesome/free-brands-svg-icons';

import Routes from './Routes';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import store from './app/store';

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const history = createBrowserHistory({ basename: baseUrl });

library.add(
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faHome,
  faStar,
  faStarHalfAlt,
  faTwitter,
  faFacebookSquare,
  faYoutube,
  faCheck,
  faPhoneVolume,
  faComment,
);

// const store = configureStore(history, {});

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
