/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchInsuranceClasses } from '../../../../app/api';

const initialState = {
  items: [],
  selected: undefined,
  status: 'idle',
  error: undefined,
};

export const fetchAsync = createAsyncThunk('insurance_classes/fetch', fetchInsuranceClasses);

export const insuranceClassSelectSlice = createSlice({
  name: 'insurance_classes',
  initialState,
  reducers: {
    select: (state, action) => {
      const [selected] = state.items.filter((i) => i.id === action.payload);
      state.selected = selected;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.selected = undefined;
        state.items = action.payload;
      })
      .addCase(fetchAsync.rejected, (state, action) => {
        state.status = 'erroneous';
        state.error = action.error.message || 'unexpected error';
      });
  },
});

export const selectors = {
  getAll: (state) => state.insuranceClasses.items,
  getSelected: (state) => state.insuranceClasses.selected,
};

export const { select } = insuranceClassSelectSlice.actions;
export default insuranceClassSelectSlice.reducer;
