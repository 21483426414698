import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'antd';

import './tab.scss';

const Tab = ({ menu, setMenu, title }) => (
  <div className="tab">
    <Button
      type="primary"
      className={classNames('tab-item', { active: menu === 1 })}
      onClick={() => setMenu(1)}
    >
      {title[0]}
    </Button>
    <Button
      type="button"
      className={classNames('tab-item', { active: menu === 2 })}
      onClick={() => setMenu(2)}
    >
      {title[1]}
    </Button>
  </div>
);

Tab.propTypes = {
  menu: PropTypes.number.isRequired,
  setMenu: PropTypes.func.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Tab;
