import React from 'react';
import { Helmet } from 'react-helmet';

const HowOftenWeRate = () => (
  <>
    <Helmet>
      <title>LMI ClaimsComparison | How Often We Rate</title>
    </Helmet>
    <div className="how-often-rate">
      <h1>How Often We Rate</h1>
      <p>
        As can be seen, a large proportion of the criteria used to develop the
        LMI ClaimsComparison Ratings for Category 1 Products are derived from
        the Australian Financial Services Authority, which are by their very
        nature several months old and cover a period of time. It is our
        experience however that claims service can change quickly, either for the
        better or worse. To track changes as they occur, a regular survey of
        insurance brokers, repairers, consultants to the claims function will be
        undertaken and the results will be used to update the Star ratings. As
        previously mentioned, the date of the current rating will be shown on the
        results page for the particular class of insurance.
      </p>
    </div>
  </>
);

export default HowOftenWeRate;
