/* eslint react/jsx-props-no-spreading: off */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Rate, Table } from 'antd';
import { sortBy } from 'lodash';
import { selectors } from '../InsurerSelect/insurersSlice';
import './ratingTable.scss';

const getBadgeClassName = (rating) => {
  switch (rating) {
    case 4:
      return 'with-badge-4';
    case 4.5:
      return 'with-badge-4-5';
    case 5:
      return 'with-badge-5';
    default:
      return '';
  }
};

const Ratings = (props) => {
  const { ratings } = props;

  const dataSource = sortBy(
    ratings.map((v) => ({
      key: `${v.insurerId}_${v.providerId}`,
      brand: v.insurerName,
      provider: v.providerName,
      rating: (
        <div
          className={classNames(
            'rating-table-rating',
            getBadgeClassName(v.rating),
          )}
        >
          <Rate
            key={`tr_${v.insurerId}_${v.providerId}`}
            allowHalf
            disabled
            defaultValue={v.rating}
          />
        </div>
      ),
    })),
    ['brand'],
  );

  const columnDefinition = [
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: '30%',
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
      width: '30%',
    },
    {
      title: 'LMI Claims Service Rating',
      dataIndex: 'rating',
      key: 'rating',
    },
  ];

  return (
    <Table
      {...props}
      className="rating-table"
      dataSource={dataSource}
      columns={columnDefinition}
    />
  );
};

Ratings.propTypes = {
  ratings: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  ratings: selectors.getRatingsByInsurers(state),
});

export default connect(mapStateToProps)(Ratings);
