import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import './siderMenu.scss';

const SiderMenu = ({ subRoutes }) => (
  <div className="sider-menu">
    <Link
      className={classNames('menuitem', {
        active: subRoutes === 'why-we-rate',
      })}
      to="/about-rating/why-we-rate"
    >
      <span>Why we rate?</span>
      <RightOutlined />
    </Link>
    <Link
      className={classNames('menuitem', {
        active: subRoutes === 'how-we-rate',
      })}
      to="/about-rating/how-we-rate"
    >
      <span>How we rate?</span>
      <RightOutlined />
    </Link>
    <Link
      className={classNames('menuitem', {
        active: subRoutes === 'how-often-we-rate',
      })}
      to="/about-rating/how-often-we-rate"
    >
      <span>How often we rate</span>
      <RightOutlined />
    </Link>
    <Link
      className={classNames('menuitem', {
        active: subRoutes === 'get-your-badge',
      })}
      to="/about-rating/get-your-badge"
    >
      <span>Get your badge</span>
      <RightOutlined />
    </Link>
  </div>
);

SiderMenu.propTypes = {
  subRoutes: PropTypes.string,
};

SiderMenu.defaultProps = {
  subRoutes: 'why-we-rate',
};

export default SiderMenu;
