import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Tab from '../../../../components/tab/Tab';
import CategoryOne from './scenes/categoryOne/CategoryOne';
import CategoryTwo from './scenes/categoryTwo/CategoryTwo';

const HowWeRate = () => {
  const [menu, setMenu] = useState(1);
  const title = ['Category 1 Products', 'Category 2 Products'];
  return (
    <>
      <Helmet>
        <title>LMI ClaimsComparison | How We Rate</title>
      </Helmet>
      <div className="how-rate">
        <h1>How we rate?</h1>
        <p>
          LMI - Experts in general insurance. That&rsquo;s our claim! LMI
          ClaimsComparison have relied upon a range of data to rate the claims
          service of each of the listed Australian Insurers and the majority of
          Underwriting Agencies by policy class. These products are grouped into
          two broad categories, Category 1 and Category 2.
        </p>
        <p>
          Different methodology is used to determine the published claims star
          rating for Category 1 and Category 2 Products. We set out the criteria
          used to rate products in each class below.
        </p>
        <div className="category">
          <Tab menu={menu} setMenu={setMenu} title={title} />
          {menu === 1 && <CategoryOne />}
          {menu === 2 && <CategoryTwo />}
        </div>
      </div>
    </>
  );
};

export default HowWeRate;
