/* eslint react/jsx-props-no-spreading: off */

import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Select } from 'antd';
import './insuranceClassSelect.scss';

import { fetchAsync, select, selectors } from './insuranceClassesSlice';

const { Option } = Select;

export const InsuranceClassSelect = (props) => {
  const {
    className,
    insuranceClasses,
    onComponentDidMount,
    selected,
    ...domAttributes
  } = props;

  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    onComponentDidMount();
  }, [onComponentDidMount]);

  React.useEffect(() => {
    setOptions(
      insuranceClasses.map((c) => (
        <Option key={`opt_${c.id}`} value={c.id}>
          {c.name}
        </Option>
      )),
    );
  }, [insuranceClasses]);

  return (
    <Select
      {...domAttributes}
      className={classNames('insurance-class-select', className)}
      size="large"
      value={selected && selected.id}
    >
      {options}
    </Select>
  );
};

InsuranceClassSelect.propTypes = {
  insuranceClasses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  onComponentDidMount: PropTypes.func.isRequired,
  className: PropTypes.string,
  selected: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

InsuranceClassSelect.defaultProps = {
  className: undefined,
  selected: undefined,
};

const mapStateToProps = (state) => ({
  insuranceClasses: selectors.getAll(state),
  selected: selectors.getSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
  onComponentDidMount: () => dispatch(fetchAsync()),
  onChange: (id, option) => {
    axios.post('/api/stats/events', {
      category: 'user',
      action: 'selected class of insurance (non-ReactGA)',
      label: `${option.children},${id}`,
    });
    dispatch(select(id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsuranceClassSelect);
