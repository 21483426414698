import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imageAward from './assets/awards.jpg';
import imageFree from './assets/badges.jpg';

import './whyWeRate.scss';

const matters = [
  {
    id: '1',
    content:
      'The coverage afforded by the policy - does the policy cover my loss?',
  },
  {
    id: '2',
    content:
      'The financial strength rating of the Insurer – will they have the funds to meet my claim promptly, particularly at the time of a natural disaster where many customers are claiming?',
  },
  {
    id: '3',
    content: 'The Claims Service provided by the Insurer.',
  },
];

const images = [
  {
    id: '1',
    image: imageAward,
    title: 'Annual Mansfield Awards',
  },
  {
    id: '2',
    image: imageFree,
    title: 'Claims Service Rating Badge',
  },
];

const WhyWeRate = () => (
  <>
    <Helmet>
      <title>LMI ClaimsComparison | Why We Rate</title>
    </Helmet>
    <div className="why-rate">
      <h1>Why we rate?</h1>
      <p>
        The reality is that not all Insurers are the same. Just like the
        policies they sell which range from broad coverage to very narrow cover,
        claims departments react differently. Some provide the very highest
        level of customer service while others move the Insured from a customer
        to a cost centre the moment a claim occurs.
      </p>
      <h3>What really matters</h3>
      <p>
        While far too many people focus on price when it comes to insurance,
        this in reality is of little consequence when it comes to making a
        claim. When a loss occurs, what really matters are:
      </p>
      <div className="check-list">
        {matters.map((item) => (
          <div className="check-list-item" key={item.id}>
            <FontAwesomeIcon icon="check" />
            <p>{item.content}</p>
          </div>
        ))}
      </div>
      <p>
        LMI PolicyComparison has been providing comparisons of the features and
        benefits of policies in a wide range of general insurance products for
        over 10 years to the point where it has become a highly trusted resource
        which is relied upon to understand the differences between policies.
      </p>
      <p>
        The issue for the typical Insured or Insurance Broker is how do you find
        out before you have a claim what the claims service is likely to be?
        Obviously, it is too late afterwards. With this in mind, we now bring
        you LMI ClaimsComparison where we rate claims service of the major
        Australian Insurers.
      </p>
      <h3>Recognising Claim Excellence</h3>
      <p>
        LMI Claims Comparison wish to acknowledge teams that provide first class
        claims service to their clients. Not only is this done to reward those
        teams but also to drive positive change in the insurance industry to
        deliver better service to their clients.
      </p>

      <div className="image-list">
        {images.map((item) => (
          <div className="image-list-item" key={item.id}>
            <div
              style={{ backgroundImage: `url(${item.image})` }}
              className="image-list-item-image"
            />
            <p>{item.title}</p>
          </div>
        ))}
      </div>

      <h3>We do this in the following ways</h3>
      <p>
        <ol className="category-one-list">
          <li>
            Those insurers that earn a 4, 4.5 or 5 star claims rating are
            provided with a&nbsp;
            <strong>badge&nbsp;</strong>
            <span>to promote their high quality claims service.</span>
          </li>
          <li>
            In conjunction with InsuranceNEWS, LMI Group host the annual
            Mansfield Awards for Claims Excellence. Five awards are presented to
            the best claims team in the following categories:
            <div>
              <span className="check-icon">
                <FontAwesomeIcon icon="check" />
              </span>
              Personal Lines (Home Building and Contents, Private Motor,
              Caravan, Pet, and or Pleasure Craft);
            </div>
            <div>
              <span className="check-icon">
                <FontAwesomeIcon icon="check" />
              </span>
              Small and Medium Enterprise;
            </div>
            <div>
              <span className="check-icon">
                <FontAwesomeIcon icon="check" />
              </span>
              Corporate;
            </div>
            <div>
              <span className="check-icon">
                <FontAwesomeIcon icon="check" />
              </span>
              Specialty; and
            </div>
            <div>
              <span className="check-icon">
                <FontAwesomeIcon icon="check" />
              </span>
              Gold Mansfield for the best overall claims team
            </div>
          </li>
          <li>
            Winners and finalists in the Mansfield Awards receive a
            <strong>&nbsp;Claims Service Rating Badge&nbsp;</strong>
            that they are able to use to further promote their excellent claims
            service.
          </li>
        </ol>
      </p>
      <h3>We make it freely available</h3>
      <p>
        We hope that by making the ratings freely available it will drive
        positive change in customer service for the good of the insuring public.
      </p>
    </div>
  </>
);

export default WhyWeRate;
