import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types'
import axios from 'axios';
import ScrollRestoration from './components/scrollRestoration/ScrollRestoration';

const App = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    axios.post('/api/stats/pageviews', {
      hostname: document.location.host,
      page: location.pathname,
      title: document.title,
      language: navigator.language || navigator.browserLanguage
    });
  }, [location]);

  return (
    <ScrollRestoration>{children}</ScrollRestoration>
  );
};

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default App;
