import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import AdBanners from '../adBanners/AdBanners';
import './layout.scss';

const { Content } = Layout;

const ClaimsComparisonLayout = ({ history, location, children }) => (
  <Layout className="layout">
    <Header history={history} location={location} />
    <Content className="layout-content">
      <>
        <AdBanners />
        {children}
      </>
    </Content>
    <Footer />
  </Layout>
);

ClaimsComparisonLayout.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default withRouter(ClaimsComparisonLayout);
