import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './navigationMenu.scss';

const { SubMenu } = Menu;

const NavigationMenu = ({ className, location, mode }) => (
  <Menu
    mode={mode}
    selectedKeys={[`${location.pathname}`]}
    className={`nav-menu ${className.length ? className : ''}`}
  >
    <Menu.Item key="/">
      <Link to="/">
        <FontAwesomeIcon icon="home" style={{ marginRight: '8px' }} />
        Home
      </Link>
    </Menu.Item>
    <SubMenu
      className="nav-submenu"
      key="subMenu"
      title={
        <span className="nav-submenu-title">
          About Rating
          {mode === 'horizontal' ? <FontAwesomeIcon icon="chevron-down" /> : ''}
        </span>
      }
    >
      <Menu.Item key="/about-rating/why-we-rate">
        <Link to="/about-rating/why-we-rate">Why we rate?</Link>
      </Menu.Item>
      <Menu.Item key="/about-rating/how-we-rate">
        <Link to="/about-rating/how-we-rate">How we rate?</Link>
      </Menu.Item>
      <Menu.Item key="/about-rating/how-often-we-rate">
        <Link to="/about-rating/how-often-we-rate">How often we rate?</Link>
      </Menu.Item>
      <Menu.Item key="/about-rating/get-your-badge">
        <Link to="/about-rating/get-your-badge">Get your badge</Link>
      </Menu.Item>
    </SubMenu>
    <Menu.Item key="/have-your-say">
      <a
        href="https://survey.alchemer.com/s3/7253764/Claims-Comparison-Survey"
        rel="noopener noreferrer"
        target="_blank"
      >
        Have your say
      </a>
    </Menu.Item>
    <Menu.Item key="/help-with-claims">
      <Link to="/help-with-claims">Help with claims</Link>
    </Menu.Item>

    <Menu.Item key="/contact-us">
      <a
        href="https://lmigroup.io/contact-us/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Contact us
      </a>
    </Menu.Item>
  </Menu>
);

NavigationMenu.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  mode: PropTypes.string.isRequired,
};

NavigationMenu.defaultProps = {
  className: '',
};

export default NavigationMenu;
