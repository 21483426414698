/* eslint-disable no-param-reassign */

import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { uniqBy, sortBy } from 'lodash';
import { fetchRatings } from '../../../../app/api';
import { selectors as InsuranceClassSelectors } from '../InsuranceClassSelect/insuranceClassesSlice';

const initialState = {
  ratings: [],
  selected: [],
  status: 'idle',
  error: undefined,
};

export const fetchAsync = createAsyncThunk('insurers/fetch', fetchRatings);

export const insurersSlice = createSlice({
  name: 'insurers',
  initialState,
  reducers: {
    select: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAsync.fulfilled, (state, action) => {
        state.selected = [];
        state.ratings = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchAsync.rejected, (state, action) => {
        state.status = 'erroneous';
        state.error = action.error.message || 'unexpected error';
      });
  },
});

const selectRatingsByInsuranceClass = createSelector(
  (state) => state.insurers.ratings,
  InsuranceClassSelectors.getSelected,
  (ratings, selectedInsuranceClass) =>
    ratings.filter((r) => r.insuranceClass.id === selectedInsuranceClass?.id),
);

export const selectors = {
  getSelected: (state) => state.insurers.selected,
  getInsurersByInsuranceClass: createSelector(
    selectRatingsByInsuranceClass,
    (ratings) =>
      sortBy(
        uniqBy(
          ratings.map((r) => ({ id: r.insurerId, name: r.insurerName })),
          'id',
        ),
        [(insurer) => insurer.name.toUpperCase()],
      ),
  ),
  getRatingsByInsurers: createSelector(
    selectRatingsByInsuranceClass,
    (state) => state.insurers.selected,
    (ratings, insurers) =>
      ratings.filter((r) => insurers.indexOf(r.insurerId) >= 0),
  ),
};

export const { select } = insurersSlice.actions;
export default insurersSlice.reducer;
