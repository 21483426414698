import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import './footer.scss';

const AntFooter = Layout.Footer;

const Footer = () => (
  <AntFooter className="footer">
    <div className="footer-links">
      <div className="footer-group">
        <h2 className="footer-title">About Us</h2>
        <p className="footer-article">
          LMI ClaimsComparison is a site that rates the claims service of
          Australian Insurers by product class without fear or favour.
        </p>
      </div>
      <div className="footer-group">
        <h2 className="footer-title">Our Services</h2>
        <ul className="footer-list">
          <li>
            <a
              href="https://www.policycomparison.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              LMI PolicyComparison
            </a>
          </li>
          <li>
            <a
              href="https://www.bicalculator.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              LMI BICalculator
            </a>
          </li>
          <li>
            <a
              href="https://www.lmiriskcoach.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              LMI RiskCoach
            </a>
          </li>
          <li>
            <a
              href="https://www.continuitycoach.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              LMI ContinuityCoach
            </a>
          </li>
          <li>
            <a
              href="https://www.lmipolicycoach.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              LMI PolicyCoach
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-group">
        <h2 className="footer-title">Need Help?</h2>
        <ul className="footer-list">
          <li>
            <a
              href="https://lmigroup.io/claims/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Help with Claims
            </a>
          </li>
          <li>
            <a
              href="https://lmigroup.io/contact-us/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Contact Us
            </a>
          </li>
          <li>
            <a
              href="https://lmigroup.io/our-team/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Our Experts
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-group">
        <h2 className="footer-title">Site Links</h2>
        <ul className="footer-list">
          <li>
            <a
              href="https://lmigroup.io/terms-conditions/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms &amp; Conditions
            </a>
          </li>
          <li>
            <a
              href="https://lmigroup.io/financial-services-guide/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Financial Services Guide
            </a>
          </li>
          <li>
            <a
              href="https://lmigroup.io/privacy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy &amp; Security
            </a>
          </li>
          <li>
            <Link to="/disclaimer">Disclaimer</Link>
          </li>
        </ul>
      </div>
    </div>
    <div className="footer-copyright">
      <span>
        &copy;
        {` Copyright 2003 - ${new Date().getFullYear()} `}
        <em>LMI ClaimsComparison&nbsp;</em>
        <span>by</span>
        <em>&nbsp;LMI Group</em>
        <span>. All Rights Reserved.</span>
      </span>
    </div>
  </AntFooter>
);

export default Footer;
