import React from 'react';
import { Button } from 'antd';
import ratingIcon from '../../assets/rating.svg';

import './haveYouSay.scss';

const HaveYouSay = () => (
  <div className="have-you-say">
    <div className="rating-icon">
      <img src={ratingIcon} alt="rating-icon" />
    </div>
    <p>
      Your feedback will allow LMI ClaimsComparison to provide accurate
      up-to-date assessment
    </p>
    <Button
      type="primary"
      href="https://survey.alchemer.com/s3/7253764/Claims-Comparison-Survey"
      target="_blank"
      rel="noopener noreferrer"
    >
      HAVE YOUR SAY
    </Button>
  </div>
);

export default HaveYouSay;
