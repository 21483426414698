import axios from 'axios';
import { message } from 'antd';

export const fetchInsuranceClasses = async () => {
  try {
    const { data } = await axios.get('/api/insurance_classes');
    return data;
  } catch (err) {
    const reason = 'Failed to fetch insurance classes.';
    message.error(reason, 5);
    throw new Error(reason);
  }
};

export const fetchRatings = async (insuranceClassId) => {
  try {
    const { data } = await axios.get('/api/ratings', {
      params: { insuranceClassId },
    });
    return data;
  } catch (err) {
    const reason = 'Failed to fetch ratings.';
    message.error(reason, 5);
    throw new Error(reason);
  }
};
