import React from 'react';
import { Table } from 'antd';
import { Link, Element } from 'react-scroll';

import './categoryOne.scss';

const dataSource = [
  {
    key: '1',
    title: 'Performance at AFCA',
    motor: '50.0%',
    others: '55.0%',
    commentId: 1,
  },
  {
    key: '4',
    title: 'Claim Contact Hours',
    motor: '10.0%',
    others: '35.0%',
    commentId: 4,
  },
  {
    key: '3',
    title: 'Choice of Repairer',
    motor: '15.0%',
    others: '0.0%',
    commentId: 5,
  },
  {
    key: '4',
    title: 'Repairer Guarantee',
    motor: '15.0%',
    others: '0.0%',
    commentId: 5,
  },
  {
    key: '5',
    title: 'Survey',
    motor: '10.0%',
    others: '10.0%',
    commentId: 6,
  },
  {
    key: '6',
    title: 'Total Weighting',
    motor: '100.0%',
    others: '100.0%',
  },
];

const columns = [
  {
    title: '',
    dataIndex: 'title',
    key: 'title',
    width: '60%',
    render(text, record) {
      const { commentId, title } = record;
      return {
        children: (
          <span>
            {title}
            {commentId && (
              <Link
                activeClass="active"
                to={`comment${commentId}`}
                smooth
                offset={-70}
              >
                <sup>{` [${commentId}]`}</sup>
              </Link>
            )}
          </span>
        ),
      };
    },
  },
  {
    title: 'Private Motor',
    dataIndex: 'motor',
    key: 'motor',
    width: '20%',
    align: 'right',
  },
  {
    title: 'All Others',
    dataIndex: 'others',
    key: 'others',
    width: '20%',
    align: 'right',
  },
];

const CategoryOne = () => (
  <div className="category-one">
    <p>
      The following classes of general insurance are included in this category:
      Domestic Building & Contents, Private Motor, Personal Travel, Residential
      Strata, Caravan, Pet and Pleasure Craft Insurance. We rate the claims
      service by product class separately as different claims teams are often
      responsible for different classes of insurance, but this is not
      necessarily the case for all insurance companies. Our comparisons may not
      include every Insurer that underwrites a particular policy in the class.
      If you would like one or more included in our comparison, please
      <a
        href="migroup.io/about-us/about-us3/contact-us/"
        target="_blank"
        rel="noopener noreferrer"
      >
        &nbsp;contact us
      </a>
      .
    </p>
    <p>
      The rating is based on both quantitative and subjective data, as well as
      policy features that are deemed by us at LMI ClaimsComparison as important
      to a positive claims experience.
    </p>

    <p>
      The product features we have used are the ability of the customer to
      choose their own repairer and a repair guarantee. Both of these features
      only apply to private motor insurance. The only other claims feature that
      applies to all claims is the contact hours for the relevant claims
      department. LMI ClaimsComparison is otherwise not a comparison of the
      features and benefits of a general insurance policy, but rather of the
      claims service provided in support of the policy. The following Table
      provides an overview of the factors and weight assigned to each use by us
      in our rating of the claims service for each class of insurance.
    </p>
    <p>
      Insurers/underwriting agencies – If your brand does not appear, the name
      or any other details have changed in the list, all you need to do is
      <a
        href="https://lmigroup.io/contact-us/"
        target="_blank"
        rel="noopener noreferrer"
      >
        &nbsp;contact us&nbsp;
      </a>
      with the correct details and we will update the site for we do not wish to
      disadvantage any organisation. This will assist us provide the best
      complimentary service to the public. There is no fee or any other charges
      for your organisation to appear.
    </p>
    <Table
      className="category-one-table"
      dataSource={dataSource}
      columns={columns}
      pagination={false}
    />
    <p>
      The source of the data is the&nbsp;
      <a
        href="https://www.afca.org.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Australian Financial Complaints Authority
      </a>
      .
    </p>
    <p>
      The Australian Financial Complaints Authority (“AFCA”) gather data each
      year and publish their results for each financial year in an Annual
      Report. The Annual Report follows the reporting requirements for external
      dispute resolution schemes set out in ASIC Regulatory Guide 267. The
      review is available in hard copy and on the AFCA website at&nbsp;
      <a href="www.afca.org.au" target="_blank" rel="noopener noreferrer">
        www.afca.org.au
      </a>
      .
    </p>
    <p>
      Claims Comparison endeavour to update their tables using the newly
      published data shortly after publication. The date that an LMI
      ClaimsComparison was last updated is shown at the foot of each comparison.
    </p>

    <p>
      Claims Comparison endeavour to update their tables using the newly
      published data shortly after publication. The date that an LMI
      ClaimsComparison was last updated is shown at the foot of each comparison.
    </p>
    <p>
      Where an Insurer has multiple brands, but report disputes to the
      Australian Financial Complaints Authority&nbsp;
      <a
        href="https://www.afca.org.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.afca.org.au
      </a>
      &nbsp; under the one Australian Financial Licence entity, LMI
      ClaimsComparison applies the same rating across all brands for that
      proportion of the overall Star Rating allocated to the FOS data.
    </p>
    <ol className="category-one-list">
      <li>
        <Element name="comment1">
          <p>
            <strong>Performance at AFCA.</strong>
{' '}
LMI ClaimsComparison weights
            and ranks Insurers claims performance based on the quantitative data
            published by www.afca.org.au, namely the number of claims brought
            before www.afca.org.au within a 12 month period, claims resolved by
            agreement & Other
<Link activeClass="active" to="comment2" smooth offset={-70}>
              <sup>[2]</sup>
            </Link>
{' '}
            and claims resolved in favour of the Insurer
            <Link activeClass="active" to="comment3" smooth offset={-70}>
              <sup>[3]</sup>
            </Link>
            . This data is analysed allowing for insurers to be ranked
            numerically and subsequently placed on a bell curb to establish
            their position within this portion of the analysis.
          </p>
        </Element>
      </li>
      <li>
        <Element name="comment2">
          <p>
            <strong>Ranked Resolution by Agreement &amp; Other.&nbsp;</strong>
            It is considered good customer practice to attempt to resolve
            disputes amicably through mediation rather than requiring a
            judgment. It is expected and accepted that commercial considerations
            will be taken into account by an Insurer. Examples may include but
            are limited to the length of time a customer has been with the
            Insurer and the amount of insurance placed with the Insurer.
          </p>
        </Element>
      </li>
      <li>
        <Element name="comment3">
          <p>
            <strong>Ranked Resolution In Favour of Insurer.&nbsp;</strong>
            These are claims that have failed at mediation, and it would be
            unfair to give a negative weighting where an Insurer has been found
            to considered a claim in accordance with their policy terms and
            conditions. Any award, including a part payment award made by AFCA
            is regarded by AFCA as having been found in favour of the Applicant
            and would act to reduce an Insurer’s star rating.
          </p>
        </Element>
      </li>
      <li>
        <Element name="comment4">
          <p>
            <strong>Claim Contact Hours.&nbsp;</strong>
            Loss or damage is potentially a stressful time for a policyholder.
            The ability to contact an Insurer and report a loss and where
            appropriate seek advice to mitigate the loss is considered important
            by LMI ClaimsComparison and therefore included in the rating. The
            contact hours are recorded from two sources: the policy document for
            the particular class of insurance and the Insurers website. The
            numbers and times are checked for accuracy quarterly. Typically
            there are three levels of contact i.e. Business hours only, extended
            business hours to cover Australian time zones and 24/7.
          </p>
          <p>
            While LMI ClaimsComparison display the telephone number for
            convenience, you should also cross check the Insurers&rsquo;
            website, your insurance broker/adviser and/or your policy.
          </p>
        </Element>
      </li>
      <li>
        <Element name="comment5">
          <p>
            <strong>Choice of Repairer/Repairer Guarantee.&nbsp;</strong>
            These criteria are only applicable to private motor and are
            considered important enough to be included in the LMI
            ClaimsComparison rating.
          </p>
        </Element>
      </li>
      <li>
        <Element name="comment6">
          <p>
            <strong>Survey.&nbsp;</strong>
            <span>
              Ongoing surveys of: insurance brokers; service providers to the
              insurance industry such as builders, household items and
              jewellers, investigators, loss adjusters; and the like; and
              individuals who have sought to take part and/or selected the “Have
              Your Say” button on this site, is carried out. The methodology for
              the survey is detailed in the Section below.
            </span>
          </p>
        </Element>
      </li>
    </ol>
  </div>
);

export default CategoryOne;
