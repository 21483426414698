import React from 'react';
import PropTypes from 'prop-types';
import HowOftenWeRate from './scenes/howOftenWeRate/HowOftenWeRate';
import HowWeRate from './scenes/howWeRate/HowWeRate';
import WhyWeRate from './scenes/whyWeRate/WhyWeRate';
import GetBadge from './scenes/badges/GetBadge';
import SmallScreenMenu from './components/smallScreenMenu/SmallScreenMenu';
import SiderMenu from './components/siderMenu/SiderMenu';
import PageLayout from '../../components/pageLayout/PageLayout';

import './index.scss';

const component = ({ subRoutes }) => {
  switch (subRoutes) {
    case 'why-we-rate':
      return <WhyWeRate />;
    case 'how-we-rate':
      return <HowWeRate />;
    case 'how-often-we-rate':
      return <HowOftenWeRate />;
    case 'get-your-badge':
      return <GetBadge />;
    default:
      return <WhyWeRate />;
  }
};

const AboutRating = ({ location }) => {
  const subRoutes = location.pathname.split('/')[2];

  return (
    <PageLayout
      subRoutes={subRoutes}
      sider={<SiderMenu subRoutes={subRoutes} />}
    >
      <>
        <div className="menu-container">
          <SmallScreenMenu subRoutes={subRoutes} />
        </div>
        <div className="about-rating">{component({ subRoutes })}</div>
      </>
    </PageLayout>
  );
};

AboutRating.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

component.propTypes = {
  subRoutes: PropTypes.string.isRequired,
};

export default AboutRating;
