import React from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Image, Button } from 'antd';
import './getBadge.scss';
import badgeAd from './assets/insurance-ad.png';
import badge from './assets/badge-5.png';

const GetBadge = () => (
  <>
    <Helmet>
      <title>LMI ClaimsComparison | Get Your Badge</title>
    </Helmet>

    <div className="contact">
      <h1>Need information, badges or have a question for us?</h1>

      <div>
        <p>
          Claims Comparison rates the claims service of Australian Insurers and
          helps them tell their customers about their achievements.
        </p>
      </div>
      <Row justify="start" gutter={[16, 16]}>
        <Col md={6} sm={24}>
          <Image src={badge} preview={false} />
        </Col>
        <Col md={18} sm={24}>
          <h3>Get your companys’ badge here</h3>

          <p>
            If you want to receive marketing collateral, claims rating badges
            and more, fill out the form and a team member will get in touch with
            you.
          </p>
          <div className="btn-contact-wrap">
            <Button className="btn-contact btn-tma">
              <a
                href="https://lmigroup.io/contact-us/#Contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact us
              </a>
            </Button>
          </div>
        </Col>
      </Row>

      <div>
        <h3>Show off what you have achieved!</h3>
      </div>
      <Row justify="start" gutter={[16, 16]}>
        <Col md={12} sm={24}>
          <p>
            The insurers that earn a 4, 4.5 or 5 star claims rating are provided
            with a badge to promote their high quality claims service. We
            encourage the use of these badges across any and all marketing /
            promotional material.
          </p>
          <p>
            Badges are available in multiple formats to best suit your marketing
            collateral. Our graphic design team are happy to work with you to
            best promote your achievement. Insurers that earn a 4, 4.5 or 5 star
            claims rating are encouraged to get in touch with our team for more
            information.
          </p>
        </Col>
        <Col md={12} sm={24}>
          <Image src={badgeAd} preview={false} />
        </Col>
      </Row>
    </div>
  </>
);

export default GetBadge;
