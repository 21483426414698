import React from 'react';
import * as data from './ads';
import './adBanners.scss';

const AdBanners = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  React.useEffect(() => {
    const handle = window.setInterval(() => {
      setCurrentIndex((currentIndex + 1) % data.default.length);
    }, 10000);
    return () => {
      window.clearInterval(handle);
    };
  });
  return (
    <>
      <aside className="ad-left">
        <a
          href={data.default[currentIndex].href.left}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.default[currentIndex].images.left} alt="" />
        </a>
      </aside>
      <aside className="ad-right">
        <a
          href={data.default[currentIndex].href.right}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={data.default[currentIndex].images.right} alt="" />
        </a>
      </aside>
    </>
  );
};

export default AdBanners;
