/* eslint-disable import/prefer-default-export */

import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer,
});

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  store.middleware = (getDefaultMiddleware) => getDefaultMiddleware().concat(logger);
}

export const getStoreWithPreloadedState = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  });

export default store;
