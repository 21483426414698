import React from 'react';
import PropTypes from 'prop-types';
import HaveYouSay from '../haveYouSay/HaveYouSay';

import './pageSider.scss';

const PageSider = ({ sider }) => (
  <div className="sider">
    {sider}
    <HaveYouSay />
  </div>
);

PageSider.propTypes = {
  sider: PropTypes.node.isRequired,
};

export default PageSider;
