import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Drawer, Layout, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import NavigationMenu from './components/NavigationMenu';
import './header.scss';
import logo from './assets/logo.svg';
import iconLmi20th from './assets/icon-lmi-20th.svg';
import iconMansfieldAwards from './assets/icon-mansfield-awards-mobile.svg';

const Header = ({ location }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  return (
    <>
      <div className="topbar">
        <ul className="topbar-social">
          <li>
            <Button shape="circle" size="small">
              <a
                href="https://www.linkedin.com/company/lmi-group/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </Button>
          </li>
          <li>
            <Button shape="circle" size="small">
              <a
                href="https://www.facebook.com/LMIGroupPtyLtd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'facebook-square']} />
              </a>
            </Button>
          </li>
          <li>
            <Button shape="circle" size="small">
              <a
                href="https://www.youtube.com/user/LMIGROUP"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'youtube']} />
              </a>
            </Button>
          </li>
        </ul>
        <div className="topbar-links">
          <a
            href="https://www.lmigroup.io"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={iconLmi20th} alt="LMI Group 20th anniversary icon" />
          </a>
          <a
            href="http://www.mansfieldawards.com.au/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={iconMansfieldAwards} alt="Mansfield Awards icon" />
          </a>
        </div>
      </div>
      <Layout.Header className="header">
        <div className="header-container">
          <div className="header-brand">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <NavigationMenu
            className="header-menu"
            mode="horizontal"
            location={location}
          />
          <Button
            type="primary"
            className="btn-hamburger"
            onClick={() => setDrawerVisible(true)}
          >
            <FontAwesomeIcon icon="bars" />
          </Button>
          <Drawer
            title="LMI ClaimsComparison"
            closable={false}
            onClose={() => setDrawerVisible(false)}
            placement="right"
            visible={drawerVisible}
          >
            <NavigationMenu mode="inline" location={location} />
            <Menu className="header-drawer-menu" mode="inline">
              <Menu.Item key="www.lmigroup.io">
                <a
                  href="https://www.lmigroup.io"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={iconLmi20th}
                    alt="LMI Group 20th anniversary icon"
                  />
                </a>
              </Menu.Item>
              <Menu.Item key="www.mansfieldawards.com.au">
                <a
                  href="http://www.mansfieldawards.com.au/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={iconMansfieldAwards} alt="Mansfield Awards icon" />
                </a>
              </Menu.Item>
            </Menu>
          </Drawer>
        </div>
      </Layout.Header>
    </>
  );
};

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Header;
