import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import './disclaimer.scss';

const Disclaimer = () => (
  <>
    <Helmet>
      <title>LMI ClaimsComparison | Disclaimer</title>
    </Helmet>
    <Layout>
      <div className="disclaimer">
        <h1>Disclaimer</h1>
        <main>
          <p>
            The comparisons provided above have been prepared as a guide and
            they are not intended to be exhaustive. Comparisons are based on the
            available information as outlined under the tab: &ldquo;
            <Link to="/about-rating/how-we-rate">How we rate</Link>
            &rdquo;.
          </p>
          <p>
            The actual claims service provided on a specific claim by an
            insurance company can vary due to a myriad of reasons such as the
            complexity of the claim, the size of the claim, whether any fraud
            indicators are triggered, and or whether the claim is lodged during
            a period when the industry/company is dealing with a wide spread
            natural catastrophe.
          </p>
          <p>
            Claims Service can alter by state, branch or even claims officer.
            Your local insurance broker is best placed to advise on the claims
            service in your area. While the utmost care has been taken in the
            preparation of the guide, it should not be used or relied upon as a
            substitute for detailed advice or as a basis for making a business,
            financial or insurance decision.
          </p>
          <p>
            The product itself is only one criterion that should be considered
            when making a decision on what policy to accept. Other criteria are
            the coverage afforded by the insurance policy, the financial
            strength of the underwriter and price. A decision on any one
            criterion alone is certainly not recommended.
          </p>
        </main>
      </div>
    </Layout>
  </>
);

export default Disclaimer;
