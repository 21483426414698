import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Layout as AntLayout } from 'antd';
import Layout from '../layout/Layout';
import PageSider from './components/pageSider/PageSider';
import HaveYouSay from './components/haveYouSay/HaveYouSay';

import './pageLayout.scss';

const { Content, Header } = AntLayout;

const PageLayout = ({ children, location, sider }) => {
  const subRoutes = location.pathname.split('/')[2];
  const helpClaims = location.pathname.split('/')[1];

  const generateTitle = () => {
    switch (subRoutes || helpClaims) {
      case 'why-we-rate':
        return 'WHY WE RATE';
      case 'how-we-rate':
        return 'HOW WE RATE';
      case 'how-often-we-rate':
        return 'HOW OFTEN WE RATE';
      case 'get-your-badge':
        return 'GET YOUR BADGE';
      case 'help-with-claims':
        return 'HELP WITH CLAIMS';
      default:
        return 'WHY WE RATE';
    }
  };
  return (
    <Layout>
      <AntLayout className="page-layout">
        <Header className="page-layout-header">{generateTitle()}</Header>
        <Content>
          <PageSider subRoutes={subRoutes} sider={sider} />
          <div className="content">{React.cloneElement(children)}</div>
          <div className="bottom">
            <HaveYouSay />
          </div>
        </Content>
      </AntLayout>
    </Layout>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sider: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(PageLayout);
