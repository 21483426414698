import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import Home from './scenes/home/Home';
import AboutRating from './scenes/aboutRating/index';
import Disclaimer from './scenes/disclaimer/Disclaimer';
import HelpWithClaims from './scenes/helpWithClaims/index';

const Routes = () => (
  <Router>
    <App>
      <Route exact path="/" component={Home} />
      <Route path="/about-rating/why-we-rate" component={AboutRating} />
      <Route path="/about-rating/how-we-rate" component={AboutRating} />
      <Route path="/about-rating/how-often-we-rate" component={AboutRating} />
      <Route path="/about-rating/get-your-badge" component={AboutRating} />
      <Route path="/help-with-claims" component={HelpWithClaims} />
      <Route path="/disclaimer" component={Disclaimer} />
    </App>
  </Router>
);

export default Routes;
