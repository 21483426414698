import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SiderMenu.scss';

const contact = [
  {
    id: 1,
    icon: <FontAwesomeIcon icon="phone-volume" />,
    title: 'Emergency',
    title1: 'Claim Service',
    emphasis: '1300 564 476',
    href: 'tel:1300 564 476',
  },
  {
    id: 2,
    icon: <FontAwesomeIcon icon="comment" />,
    title: 'Contact Us',
    emphasis: 'LMI Claims Services',
    href: 'https://www.lmigroup.io/services/claims/',
  },
];

const SiderMenu = () => (
  <div className="help-claim-sider">
    {contact.map((item) => (
      <a
        href={item.href}
        target="_blank"
        rel="noopener noreferrer"
        key={item.id}
        className="contact"
      >
        <div className="contact-icon">{item.icon}</div>
        <div className="contact-info">
          <p>
            {item.title}
            {item.title1 && <em>{item.title1}</em>}
            <span>{item.emphasis}</span>
          </p>
        </div>
      </a>
    ))}
  </div>
);

export default SiderMenu;
