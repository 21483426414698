/* eslint react/jsx-props-no-spreading: off */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * This component is used as a workaround to resolve warnings thrown by React Slick.
 */
const ReactSlickButton = ({ currentSlide, slideCount, children, ...props }) => (
  <div {...props}>{children}</div>
);

ReactSlickButton.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  children: PropTypes.node.isRequired,
};

ReactSlickButton.defaultProps = {
  currentSlide: undefined,
  slideCount: undefined,
};

export default ReactSlickButton;
