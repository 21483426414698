import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLayout from '../../components/pageLayout/PageLayout';
import Tab from '../../components/tab/Tab';
import SiderMenu from './components/siderMenu/SiderMenu';
import triggers from './assets/triggers.png';
import satisfaction from './assets/satisfaction.png';

import './index.scss';

const HelpWithClaims = () => {
  const [menu, setMenu] = useState(1);
  const title = ['How LMI Rate', 'When is LMI Claims Services used'];
  return (
    <>
      <Helmet>
        <title>LMI ClaimsComparison | Help with Claims</title>
      </Helmet>
      <PageLayout title="HELP WITH CLAIMS" sider={<SiderMenu />}>
        <div className="help-claim">
          <h1>Need help with Claims?</h1>
          <p>
            LMI provide claims preparation services, that is we assist policy
            holders with preparing a claim under an insurance policy. The
            company has 11 offices in Australia and New Zealand. It also has a
            separate legal (LMI Legal Pty Ltd), forensic (LMI Forensic), and
            mining (LMI Mining) divisions that assist where necessary and
            provide a range of related services.
          </p>
          <p>
            While the team at LMI can assist in all classes of insurance, we
            specialise in major property, business interruption, contract works,
            public and product liability, product recall, and marine.
          </p>
          <p>
            Many commercial insurance policies such as Industrial Special Risks,
            Business Packs, Office Packs, Strata, and Product Recall policies
            provide cover for claims preparation.
          </p>
          <p>To get an expert to assist you please contact:</p>

          <div className="contact-country">
            <h3>Australia</h3>
            <div>
              <FontAwesomeIcon icon="phone-volume" />
              <a href="tel:1300564476">&nbsp;1300 LMI GROUP 1300 564 476</a>
              <span>
                <span className="italic">
                  &nbsp;(within Australia only)&nbsp;
                </span>
                or
                <a href="tel:61398359900">&nbsp;+61 3 9835 9900</a>
              </span>
            </div>
            <div>
              <FontAwesomeIcon icon="comment" />
              <a href="Claims@LMIGroup.com">&nbsp;Claims@LMIGroup.com</a>
            </div>
          </div>

          <div className="contact-country">
            <h3>New Zealand</h3>
            <div>
              <FontAwesomeIcon icon="phone-volume" />
              <a href="tel:0800001964">&nbsp;0800 001 964</a>
              <span>
                <span className="italic">
                  &nbsp;(within New Zealand only)&nbsp;
                </span>
                or
                <a href="tel:6494146330">&nbsp;+64 9414 6330</a>
              </span>
            </div>
            <div>
              <FontAwesomeIcon icon="comment" />
              <a href="mailto:nz@lmigroup.com">&nbsp;nz@lmigroup.com</a>
            </div>
          </div>

          <Tab menu={menu} setMenu={setMenu} title={title} />
          <div className="tab-content">
            {menu === 1 && (
              <>
                <p>
                  LMI Surveyed insurance brokers and asked what triggered them
                  to recommend LMI Claims Services to their clients following a
                  claim. The main reasons given were:
                </p>
                <div
                  style={{ backgroundImage: `url(${triggers})` }}
                  className="tab-content-image"
                />
              </>
            )}

            {menu === 2 && (
              <>
                <p>
                  Independent research house&nbsp;
                  <a
                    href="https://brandmatters.com.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    brandmatters
                  </a>
                  &nbsp;carried out research as to how insurance brokers rate
                  the claims preparation and advocacy service provided by LMI
                  Claims Services.
                </p>
                <div
                  style={{ backgroundImage: `url(${satisfaction})` }}
                  className="tab-content-image"
                />
              </>
            )}
          </div>
          <div className="help-claim-bottom">
            <SiderMenu />
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default HelpWithClaims;
