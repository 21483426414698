import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import './smallScreenMenu.scss';

const SmallScreenMenu = ({ subRoutes }) => (
  <div className="small-screen-menu">
    <Menu mode="horizontal" selectedKeys={[subRoutes]}>
      <Menu.Item key="why-we-rate">
        <Link to="/about-rating/why-we-rate">Why we rate</Link>
      </Menu.Item>
      <Menu.Item key="how-we-rate">
        <Link to="/about-rating/how-we-rate">How we rate</Link>
      </Menu.Item>
      <Menu.Item key="how-often-we-rate">
        <Link to="/about-rating/how-often-we-rate">How often we rate</Link>
      </Menu.Item>
    </Menu>
  </div>
);

SmallScreenMenu.propTypes = {
  subRoutes: PropTypes.string,
};

SmallScreenMenu.defaultProps = {
  subRoutes: 'why-we-rate',
};

export default SmallScreenMenu;
