import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Carousel, Form, Row, Col, Image } from 'antd';
import Icon from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../../components/layout/Layout';
import BackTop from '../../components/backTop/BackTop';
import {
  InsuranceClassSelect,
  InsurerSelect,
  RatingTable,
  ReactSlickButton,
  insuranceClassSelectors,
} from './components';
import { selectors as insurerSelectors } from './components/InsurerSelect/insurersSlice';
import './home.scss';
import coverImg from './assets/banner-new.jpg';
import mansfieldImg from './assets/mansfield-2024.jpg';
import iconInfo from './assets/icon-info.png';
import iconLamp from './assets/icon-lamp.png';
import iconBadge from './assets/icon-badge.png';
import iconRating from './assets/icon-rating.png';
import { ReactComponent as RatingSvg } from './assets/icon-survey.svg';

const Home = ({ insuranceClass, insurers }) => {
  const showRatings = insurers && insurers.length && insuranceClass;
  const mansfieldContent = 'winner';

  const ratings = showRatings ? (
    <div className="home-form-results">
      <RatingTable />
      <p>
        The searchable results shown above are a &ldquo;point in time&rdquo;
        result published annually on the day after The Mansfield Awards.
      </p>
    </div>
  ) : null;

  const MansfieldIntro = () => (
    <>
      <div className="home-notice-content">
        <div className="tma-section">
          <p>
            We are extremely excited to have this year&apos;s Mansfield Awards
            event for the 8th consecutive year to recognise the fantastic work
            of claims with you!
          </p>
          <p>
            <strong>
              The event will be held at The Establishment Ball Room in Sydney on
              the 25/7/2024, 6PM (AEST).
            </strong>
          </p>
          <p>
            We wanted to let you know how to secure your spot to the 2024
            Mansfield Awards.
          </p>
          <p>
            <Button className="btn-tma" type="primary">
              <a
                href="https://mansfieldawards.com.au/shop/"
                target="_blank"
                rel="noopener noreferrer"
              >
                PURCHASE TICKETS
              </a>
            </Button>
          </p>
          <p>
            You can help decide the Mansfield Awards by completing the survey.
          </p>
          <p>
            <Button className="btn-tma" type="primary">
              <a
                href="https://survey.alchemer.com/s3/7253764/Claims-Comparison-Survey"
                target="_blank"
                rel="noopener noreferrer"
              >
                HAVE YOUR SAY
              </a>
            </Button>
          </p>
        </div>
        <div className="tma-section">
          <Button className="btn-tma">
            <a
              href="https://mansfieldawards.com.au/purple-mansfield-nomination-form/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nominate
            </a>
          </Button>
        </div>
      </div>
    </>
  );

  const MansfieldWinner = () => (
    <div className="home-notice-content">
      <div className="tma-section">
        <h4>
          <strong>Gold Mansfield</strong>
        </h4>
        <p>
          <strong>Winner: Vero Insurance. </strong>
          Finalists: FM, NTI Limited, PassportCard Australia Pty Ltd, Suncorp
          Insurance, Vero Insurance, Zurich Australian Insurance Limited.
        </p>
        <h4>
          <strong>Personal Lines</strong>
        </h4>
        <p>
          <strong>Winner: Suncorp Insurance. </strong>
          Finalists: Hollard Personal Insurance, NRMA Insurance, RAA Insurance
          Pty Ltd, RACQ Insurance Limited, RACV, Suncorp Insurance.
        </p>
        <h4>
          <strong>SME Property and Casualty</strong>
        </h4>
        <p>
          <strong>Winner: Vero Insurance. </strong>
          Finalists: Allianz Australia Insurance Limited, Ansvar Insurance
          Limited, Capricorn Mutual Limited, Vero Insurance.
        </p>
        <h4>
          <strong>Corporate Property and Casualty</strong>
        </h4>
        <p>
          <strong>Winner: FM and Zurich Australian Insurance Limited.</strong>
          Finalists: Allianz Australia Insurance Limited, FM, Liberty Special
          Markets, Vero Insurance, Zurich Australian Insurance Limited.
        </p>
        <h4>
          <strong>Specialty </strong>
        </h4>
        <p>
          <strong>Winner: NTI Limited & PassportCard Australia Pty Ltd.</strong>
          Finalists: Arch Underwriting at Lloyd’s, EBM Rent Cover, GT Insurance,
          Interruption Underwriting Agencies (IUA), Mecon Insurance Pty Ltd,
          Millennium Underwriting Agencies Pty Ltd, NTI Limited, PassportCard
          Australia Pty Ltd, Pen Underwriting Pty Ltd.
        </p>
        <h4>
          <strong>Purple Mansfield </strong>
        </h4>
        <p>
          <strong>Winner: Denise Di Giacomo </strong>- Allianz Australia
          Insurance Limited
        </p>
      </div>
    </div>
  );

  return (
    <Layout>
      <BackTop />
      <section className="home-hero">
        <div
          className="home-banner"
          style={{ backgroundImage: `url(${coverImg})` }}
        >
          <div className="home-banner-text">
            <h2>Find the very best</h2>
            <h1>CLAIMS SERVICE</h1>
            <p>
              We rate the claims service of Australian Insurers by product class
              without fear or favour.
            </p>
            <p>
              <FontAwesomeIcon icon="star" />
              <FontAwesomeIcon icon="star" />
              <FontAwesomeIcon icon="star" />
              <FontAwesomeIcon icon="star" />
              <FontAwesomeIcon icon="star" />
            </p>
          </div>
        </div>
        <div className="home-form">
          <h1>Compare Claims Services</h1>
          <Form layout="vertical" className="home-form-fields" colon={false}>
            <Form.Item label="Class of Insurance">
              <InsuranceClassSelect
                className="select"
                placeholder="Select class of insurance"
              />
            </Form.Item>
            <Form.Item label="Insurance Brand">
              <InsurerSelect className="select" placeholder="Select insurers" />
            </Form.Item>
          </Form>
          <div className="home-form-note">Last update: 25th July 2024</div>
          {ratings}
        </div>
      </section>
      <section className="home-introduction">
        <h1>Why LMI ClaimsComparison?</h1>
        <h2>
          Don&apos;t wait until you have a claim to find out how your Insurer
          handles claims. By then it&apos;s too late!
        </h2>
        <p>
          Welcome to LMI ClaimsComparison, a site that rates the claims service
          of Australian Insurers by product class without fear or favour. Simply
          choose the type of insurance you are interested in and then choose any
          of the Insurers listed to see their comparative rating from&nbsp;
          <FontAwesomeIcon icon="star-half-alt" />
          &nbsp;to&nbsp;
          <FontAwesomeIcon icon="star" />
          <FontAwesomeIcon icon="star" />
          <FontAwesomeIcon icon="star" />
          <FontAwesomeIcon icon="star" />
          <FontAwesomeIcon icon="star" />
          <span>
            . Five stars being the highest possible rating denoting excellent
            claims service.
          </span>
        </p>
      </section>
      <section className="home-survey">
        <div className="home-survey-text">
          <Icon component={RatingSvg} style={{ color: '#f1c400' }} />
          <span>
            Your feedback will allow
            <strong>&nbsp;LMI ClaimsComparison&nbsp;</strong>
            to provide accurate up-to-date results.
          </span>
        </div>
        <Button className="btn-survey">
          <a
            href="https://survey.alchemer.com/s3/7253764/Claims-Comparison-Survey"
            target="_blank"
            rel="noopener noreferrer"
          >
            HAVE YOUR SAY
          </a>
        </Button>
      </section>
      <section className="home-explanation">
        <ul>
          <li>
            <img src={iconLamp} alt="icon-lamp" />
            <h2>Why we rate?</h2>
            <p>
              We purchase insurance to protect us when we have a loss, this
              makes the way an Insurance company handles claims a vital aspect
              that should be considered.
            </p>
            <Link to="/about-rating/why-we-rate">Read more</Link>
          </li>
          <li>
            <img src={iconRating} alt="icon-rating" />
            <h2>How we rate?</h2>
            <p>
              LMI ClaimsComparison have relied upon a wide range of data to rate
              the claims service.
            </p>
            <Link to="/about-rating/how-we-rate">Read more</Link>
          </li>
          <li>
            <img src={iconBadge} alt="icon-info" />
            <h2>Get your badge</h2>
            <p>
              The insurers that earn a 4, 4.5 or 5 star claims rating are
              provided with a badge to promote their high quality claims
              service.
            </p>
            <a
              href="/about-rating/get-your-badge"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more
            </a>
          </li>
          <li>
            <img src={iconInfo} alt="icon-info" />
            <h2>Help with Claims?</h2>
            <p>
              LMI provide Claims Preparation services, that is we assist you in
              preparing a claim under your insurance policy.
            </p>
            <a
              href="https://lmigroup.io/claims/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more
            </a>
          </li>
        </ul>
      </section>
      <section className="home-mansfield">
        <Row>
          <Col span={24}>
            <h1>The Mansfield Awards 2024!</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={24}>
            {mansfieldContent === 'winner' ? (
              <MansfieldWinner />
            ) : (
              <MansfieldIntro />
            )}
          </Col>
          <Col lg={12} md={24}>
            <div className="home-notice-content">
              <div className="tma-section">
                <Image src={mansfieldImg} preview={false} />
                <div className="tma-section">
                  <p>
                    To find out more about The Mansfield Awards or to register
                    your interest, visit&nbsp;
                    <a
                      href="https://www.MansfieldAwards.com.au"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      www.MansfieldAwards.com.au
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      <section className="home-links">
        <h1>Links and Resources</h1>
        <Carousel
          autoplay
          dots={false}
          slidesToShow={6}
          slidesToScroll={1}
          arrows
          prevArrow={
            <ReactSlickButton>
              <FontAwesomeIcon icon="chevron-left" />
            </ReactSlickButton>
          }
          nextArrow={
            <ReactSlickButton>
              <FontAwesomeIcon icon="chevron-right" />
            </ReactSlickButton>
          }
          responsive={[
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 425,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          <div className="home-links-item">
            <div className="home-links-item-inner">
              <a
                href="https://www.afca.org.au/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/assets/afca.png" alt="afca" />
              </a>
            </div>
          </div>
          <div className="home-links-item">
            <div className="home-links-item-inner">
              <a
                href="https://www.apra.gov.au/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/assets/apra.png" alt="" />
              </a>
            </div>
          </div>
          <div className="home-links-item">
            <div className="home-links-item-inner">
              <a
                href="http://codeofpractice.com.au/2020/10/ICA001_COP_Literature_Code_On_Screen_SP_RGB_10.1_LR2.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/assets/cop.png" alt="" />
              </a>
            </div>
          </div>
          <div className="home-links-item">
            <div className="home-links-item-inner">
              <a
                href="https://www.niba.com.au/codeofpractice/index.cfm "
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/assets/code-of-practice.png" alt="" />
              </a>
            </div>
          </div>
          <div className="home-links-item">
            <div className="home-links-item-inner">
              <a
                href="http://www.needabroker.com.au/html/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/assets/need-a-broker.png" alt="" />
              </a>
            </div>
          </div>
          <div className="home-links-item">
            <div className="home-links-item-inner">
              <a
                href="https://www.lmigroup.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/assets/lmi-group.png" alt="" />
              </a>
            </div>
          </div>
          <div className="home-links-item">
            <div className="home-links-item-inner">
              <a
                href="https://claimsprofessionals.org/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/assets/iicp.png" alt="" />
              </a>
            </div>
          </div>
          <div className="home-links-item">
            <div className="home-links-item-inner">
              <a
                href="https://www.insurancecouncil.com.au/for-consumers/code-of-practice"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/assets/insurance-council.png" alt="" />
              </a>
            </div>
          </div>
          <div className="home-links-item">
            <div className="home-links-item-inner">
              <a
                href="https://lmilegal.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/assets/lmi-legal.png" alt="" />
              </a>
            </div>
          </div>
        </Carousel>
      </section>
    </Layout>
  );
};

Home.propTypes = {
  insuranceClass: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  insurers: PropTypes.arrayOf(PropTypes.number),
};

Home.defaultProps = {
  insuranceClass: undefined,
  insurers: [],
};

const mapStateToProps = (state) => ({
  insuranceClass: insuranceClassSelectors.getSelected(state),
  insurers: insurerSelectors.getSelected(state),
});

export default connect(mapStateToProps)(Home);
