import { combineReducers } from '@reduxjs/toolkit';

import {
  insuranceClassesReducer,
  insurersReducer,
} from '../scenes/home/components';

const rootReducer = combineReducers({
  insuranceClasses: insuranceClassesReducer,
  insurers: insurersReducer,
});

export default rootReducer;
