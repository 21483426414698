import React, { useState } from 'react';
import { Rate } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconSpeed } from './assets/speed-meter.svg';
import { ReactComponent as IconProactiveness } from './assets/tick-inside-circle.svg';
import { ReactComponent as IconAccuracy } from './assets/target.svg';
import { ReactComponent as IconFairness } from './assets/balance.svg';

import './categoryTwo.scss';

const steps = [
  {
    id: 1,
    icon: IconSpeed,
    title: 'SPEED',
  },
  {
    id: 2,
    icon: IconProactiveness,
    title: 'PROACTIVENESS',
  },
  {
    id: 3,
    icon: IconAccuracy,
    title: 'ACCURACY',
    intro: '',
    list: [],
  },
  {
    id: 4,
    icon: IconFairness,
    title: 'FAIRNESS',
    intro: '',
    list: [],
  },
];

const stepContent = {
  1: {
    intro: [
      {
        id: 1,
        content: 'Speed relates to the response of the Insurer in respect of:',
      },
    ],
    list: [
      {
        id: 1,
        content: 'Initial acknowledgement',
      },
      {
        id: 2,
        content: 'Acceptance or denial of the claim',
      },
      {
        id: 3,
        content: 'Action on receipt of assessment',
      },
      {
        id: 4,
        content: 'Progress payments (if applicable)',
      },
      {
        id: 5,
        content: 'Final payment',
      },
    ],
  },
  2: {
    intro: [''],
    list: [
      {
        id: 1,
        content:
          'of loss adjuster (if appointed), did they check on you/the client or',
      },
      {
        id: 2,
        content:
          'of the claims department, was it necessary for you/someone to keep chasing them?',
      },
    ],
  },
  3: {
    intro: [''],
    list: [
      {
        id: 1,
        content:
          'Were the circumstances giving rise to the claim correctly identified by the loss adjuster, investigator, Insurer, engineer and/or other consultants appointed by the Insurer?',
      },
      {
        id: 2,
        content:
          "Were the policy's Terms & Conditions correctly interpreted in your view?",
      },
    ],
  },
  4: {
    intro: [''],
    list: [
      {
        id: 1,
        content: 'Was their entitlement explained to the respondent?',
      },
      {
        id: 2,
        content:
          'Was the respondent refused assistance that they felt the policy provided or the Insurer ought to have supplied?',
      },
      {
        id: 3,
        content: 'Was the original settlement offer fair and reasonable?',
      },
    ],
  },
};

const rate = [
  {
    id: 1,
    rate: 1,
    value: 'Unsatisfactory',
  },
  {
    id: 2,
    rate: 2,
    value: 'Poor',
  },
  {
    id: 3,
    rate: 3,
    value: 'Satisfactory',
  },
  {
    id: 4,
    rate: 4,
    value: 'Good',
  },
  {
    id: 5,
    rate: 5,
    value: 'Excellent',
  },
];

const CategoryTwo = () => {
  const [selectedStep, setStep] = useState(1);
  return (
    <div className="category-two">
      <p>
        Category 2 covers other classes of general insurance not listed as
        Category 1. In this category the survey is the only source of data for
        the insurance Product’s star rating on claims service.
      </p>
      <p>
        The star rating has been calculated following a regular survey
        commencing from the 3rd quarter of 2014, along with responses left on
        the site under the heading&nbsp;
        <a
          href="https://survey.alchemer.com/s3/7253764/Claims-Comparison-Survey"
          target="_blank"
          rel="noopener noreferrer"
        >
          &ldquo;Have Your Say&rdquo;
        </a>
        &nbsp;with equal weighting given to four (4) categories that respondents
        are asked to consider when completing the survey/questionnaire.
      </p>
      <p>Respondents were asked to consider the following:</p>
      <div className="figure">
        <div className="steps">
          {steps.map((step) => (
            <div
              key={step.id}
              role="button"
              onKeyDown={(e) => e.preventDefault()}
              tabIndex="0"
              className={classNames('step-item', {
                active: selectedStep === step.id,
              })}
              onClick={() => setStep(step.id)}
            >
              <div className="icon-container">
                <Icon component={step.icon} />
              </div>
              <span>{step.title}</span>
            </div>
          ))}
        </div>
        {stepContent[selectedStep].intro.map((item) => (
          <p className="intro" key={item.id}>
            {item.content}
          </p>
        ))}

        <div className="check-list">
          {stepContent[selectedStep].list.map((item) => (
            <div className="check-list-item" key={item.id}>
              <FontAwesomeIcon icon="check" />
              <p>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="rates">
        <p>
          There are five Measurements to grade each of the four (4) criteria:
        </p>
        <div className="rates-criteria">
          {rate.map((item) => (
            <span key={item.id}>
              <Rate defaultValue={item.rate} disabled />
              <span>{item.value}</span>
            </span>
          ))}
        </div>
        <p>
          <strong>Please note:&nbsp;</strong>
          To avoid any potential conflict of interest or allegation of bias, LMI
          Group and InsuranceNEWS directors and employees are not permitted,
          other than for a claim on a policy held by them as individuals, to
          participate in this survey.
        </p>
      </div>
    </div>
  );
};

export default CategoryTwo;
