/* eslint react/jsx-props-no-spreading: off */

import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Select } from 'antd';
import {
  fetchAsync,
  select,
  selectors as insurerSelectors,
} from './insurersSlice';
import { selectors as insuranceClassSelectors } from '../InsuranceClassSelect/insuranceClassesSlice';
import './insurerSelect.scss';

const { Option } = Select;

export const InsurerSelect = (props) => {
  const {
    className,
    onInsuranceClassSelected,
    insuranceClass,
    selected,
    insurers,
    ...domAttributes
  } = props;

  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (insuranceClass) {
      onInsuranceClassSelected(insuranceClass);
    }
  }, [insuranceClass]);

  React.useEffect(() => {
    const handle = document.querySelector(
      '.ant-select-search__field__wrap input',
    );
    if (handle) {
      handle.setAttribute('readonly', true);
    }
  });

  React.useEffect(() => {
    setOptions(
      insurers.map((v) => (
        <Option key={`opt_${v.id}`} value={v.id}>
          {v.name}
        </Option>
      )),
    );
  }, [insurers]);

  return (
    <Select
      {...domAttributes}
      className={classNames('insurer-select', className)}
      mode="multiple"
      size="large"
      onSelect={(id, option) => {
        axios.post('/api/stats/events', {
          category: 'user',
          action: 'selected insurance brand (non-ReactGA)',
          label: `${option.children},${id}`,
        });
      }}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      maxTagCount={3}
      maxTagTextLength={8}
      maxTagPlaceholder={`+ ${selected.length - 3}`}
      disabled={!insuranceClass}
      value={selected}
    >
      {options}
    </Select>
  );
};

InsurerSelect.propTypes = {
  className: PropTypes.string,
  insuranceClass: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onInsuranceClassSelected: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  insurers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

InsurerSelect.defaultProps = {
  className: undefined,
  insuranceClass: undefined,
};

const mapStateToProps = (state) => ({
  insuranceClass: insuranceClassSelectors.getSelected(state),
  insurers: insurerSelectors.getInsurersByInsuranceClass(state),
  selected: insurerSelectors.getSelected(state),
});

const mapDispatchToProps = (dispatch) => ({
  onInsuranceClassSelected: (insuranceClass) =>
    dispatch(fetchAsync(insuranceClass.id)),
  onChange: (ids) => dispatch(select(ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsurerSelect);
