import React from 'react';
import { BackTop as AntBackTop } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './backTop.scss';

const BackTop = () => (
  <AntBackTop className="backtop-outer">
    <div className="backtop-inner">
      <FontAwesomeIcon icon="chevron-up" />
    </div>
  </AntBackTop>
);

export default BackTop;
